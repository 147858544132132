import React, { useState } from "react";
import styled from "styled-components";
import LogoImg from "../utils/Images/logo.png";
import { NavLink } from "react-router-dom";
// import Button from "./Button";
import {
  // SearchRounded,
  // ShoppingCartOutlined,
  // FavoriteBorder,
  MenuRounded
} from "@mui/icons-material";

const Nav = styled.div`
  height: 80px;
  display: flex;
  align-item: center;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  background: white;
`;
const NavbarContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  padding-inline: 50px;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
`;
const NavLogo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 9px 6px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: inherit;
`;
const Logo = styled.img`
  width: 250px;
  @media screen and (max-width: 768px) {
    width: 160px;
  }
`;
const NavItems = styled.ul`
  // width: 100%;
   display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  list-style: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Navlink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text_primary};
  font-weight: 500;
  // font-size: 15px;
  cursor: pointer;
  transition: all 1s slide-in;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  &.active {
    color: ${({ theme }) => theme.primary};
    border-bottom: 1.8px solid ${({ theme }) => theme.primary};
  }
`;


const MobileIcon = styled.div`
  color: ${({ theme }) => theme.text_primary};
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const MobileMenu = styled.ul`
  display: flex;
  background: white;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  padding: 20px;
  list-style: none;
  width: 80%;
  padding: 12px 40px 24x 40px;
  background: ${({ theme }) => theme.card_light + 99};
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 10000;
  transition: all 0.6s ease-in-out;
  transform: ${({ isOpen}) =>
    isOpen ? "translateY(0)" : "translateY(-100%)"
  };
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: ${({isOpen})=> (isOpen ? "100%" : "0")};
  z-index: ${({isOpen})=> (isOpen ? "1000" : "-1000")};
`;

const Navbar = () => {
  const [isOpen, setIsOpen]= useState(false);
  return (
    <Nav>
      <NavbarContainer>
        
        <NavLogo>
          <NavLink to="/">
            <Logo src={LogoImg} />
          </NavLink>
        </NavLogo>
        <NavItems>
          <Navlink to="/">Home</Navlink>
          <Navlink to="/shop">Shop</Navlink>
          <Navlink to="/about">About</Navlink>
          <Navlink to="/contact">Contact</Navlink>
        </NavItems>

        { isOpen &&(

    <MobileMenu isOpen={isOpen}>
      <Navlink to="/" onClick={()=> setIsOpen(!isOpen)}>Home</Navlink>
      <Navlink to="/shop" onClick={()=> setIsOpen(!isOpen)}>Shop</Navlink>
          <Navlink to="/about" onClick={()=> setIsOpen(!isOpen)}>About</Navlink>
          <Navlink to="/contact" onClick={()=> setIsOpen(!isOpen)}>Contact</Navlink>
   
    </MobileMenu>
    
)}

        <MobileIcon onClick={()=> setIsOpen(!isOpen)} >
            <MenuRounded style={{ color: "inherit" }}/>
        </MobileIcon>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
