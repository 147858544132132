import React from 'react'
import styled from 'styled-components'
import logo from "../utils/Images/logo.png"
import { NavLink } from "react-router-dom";


const FooterContainer= styled.div`
background-color: #F7F7FF;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  width: 100%;
`;
const FooterSection= styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
gap:100px;
padding-bottom: 20px;
padding-inline: 80px;

@media (max-width: 1024px){
  padding-bottom: 0px;
  padding-inline: 20px;
  gap:20px;
  
  }
  @media (max-width: 850px){
    flex-direction: column;
}
@media (max-width: 560px){
  // flex-direction: column;
padding-bottom: 0px;
padding-inline: 0px;
}


`;
const Div= styled.div`
display: flex;
gap: 63px;
padding-inline: 20px;
padding-right: 50px;

@media (max-width: 550px){
padding-bottom: 0px;
gap: 100px;
// flex-direction: column;

}
@media (max-width: 425px){
padding-bottom: 0px;
gap: 20px;
flex-direction: column;

}
`;
// const List1= styled.ul`
// gap: 200px;
// `;
const Image= styled.img`
width: 300px;
display: flex;
jystify-content: center;
@media (max-width: 908px){
width: 270px;
}
@media (max-width: 425px){
width: 250px;
}
@media (max-width: 320px){
width: 200px;
}
`;
const Para= styled.p`
font-size: 15px;
margin-right:100px;
    text-align: start;

@media (max-width: 768px){
  margin-right:10px;
  }
  @media (max-width: 565px){
  // flex-direction: column;
padding-bottom: 0px;
padding-inline: 30px;
}
  @media (max-width: 425px){
    text-align: center;
width: 250px;
}

`;
// const List2= styled.ul`
// gap: 20px;
// `;
const Head= styled.h2``;
// const List3= styled.ul``;
// const List4= styled.ul``;
const Navlink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text_primary};
  font-weight: 500;
  // font-size: 15px;
  cursor: pointer;
  transition: all 1s slide-in;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  
`;

const Footer = () => {
  return (
    <FooterContainer>
        <FooterSection>
          <div>
            <Navlink to='/'><Image src={logo}/></Navlink>
                
                <Para>Join us in celebrating the beauty, strength, 
                  and diversity of women everywhere. Together, let’s redefine fashion, one outfit at a time.</Para>
          </div>
          <Div>
              <div className='flex column gap'>
                <Head>Link</Head>
                <Navlink to='/'>Home</Navlink>
                <Navlink to='/shop'>Shop</Navlink>
                <Navlink to='/about'>About</Navlink>
                <Navlink to='/contact'>Contact</Navlink>
              </div>
      
            <div>
            <Head>Contact</Head>
            <Navlink to='/contact'>
              <div className='flex align gap'>
                <span className="material-symbols-outlined footer-round-border footer-equal">mail</span>
                <p>info@youngfitcn.com</p>
              </div>
            </Navlink>
            <Navlink to='/contact'>
                <div className='flex align gap'>
                  <span className="material-symbols-outlined footer-round-border footer-equal">call</span>
                  <p>+86 186 8229 0483</p>
                </div>
            </Navlink>
            <Navlink to='/contact'>
              <div className='flex gap align relative'>
               <span className="material-symbols-outlined footer-round-border footer-equal absolute">home</span>
                <p style={{textAlign: "start", paddingLeft:"55px"}}>No. 38, Industrial Avenue, Economic Development Zone, Dawu County, Hubei Province, China</p>
              </div>
            </Navlink>
          </div>
        </Div>
        </FooterSection>
    </FooterContainer>
  )
}

export default Footer;
