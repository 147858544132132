import React from 'react';
import styled from "styled-components"
import Footer from "../components/Footer"
import { useParams } from 'react-router-dom';
import {products} from "../utils/products"


const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    justify-contant: center;
    align-items: center;
    width: 100%;
    height: 90%;
    overflow-y: scroll;
`;
const Wrapper = styled.div`
  flex: 1;
  max-width: 1400px;
  display: flex;
  width: 100%;
  gap: 32px;
  
  @media (max-width: 1300px){
  padding-bottom: 0px;
  padding-inline: 0px;
  
  }
  @media (max-width: 1024px){
    padding: 0px 0px;
  }
  @media (max-width: 875px){

  flex-direction: column;
  justify-content: center;
  }
`;
const ImageWrapper = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
    padding-left: 20px;
padding-block: 20px;

@media (max-width: 768px){
  justify-content: center;
  }
@media (max-width: 636px){
    flex-direction: column;
     padding-left: 10px;
  }
`;
const SmallImageWrapper = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap:20px;
@media (max-width: 768px){
    gap:10px;

  justify-content: center;
  }
  @media (max-width: 636px){
    flex-direction: row;
  }
`;
const Image = styled.img`
    height: 500px;
    border-radius: 12px;
  

    @media (max-width: 768px){
    margin-top: 50px;
    }
    @media (max-width: 556px){
    height: 380px;
    }
    @media (max-width: 400px){
    height: 270px;
    }
`;
const VarientImage = styled.img`
    height: 150px;
    border-radius: 12px;
    @media (max-width: 768px){
    margin-top: 50px;
    height: 110px;
    }
    @media (max-width: 425px){
    height: 90px;
    }
`;

const Details = styled.div`
display: flex;
flex: 1;
gap: 18px;
flex-direction: column;
padding: 14px 20px;
@media (max-width: 768px){
    padding: 14px 50px;
    
    }
@media (max-width: 320px){
    padding: 14px 30px;
    
    }
`;
const Title = styled.div`
font-size: 28px;
font-weight: 600;
color: ${({theme})=> theme.text_primary};
`;

const Desc = styled.div`
font-size: 16px;
font-weight: 400;
color: ${({theme})=> theme.text_primary};
`;
const Price = styled.div`
display: flex;
align-items: center;
gap: 8px;
font-size: 22px;
font-weight: 500;
color: ${({theme})=> theme.text_primary};
`;


const ProductDetails = () => {

  const {pid}= useParams();
  const product= products.find((e)=>{
    return e.id==={pid}.pid;

})
  return (
    <Container>
  <Wrapper>
  <ImageWrapper key={product.id}>
      <Image src={product.img}/>
  <SmallImageWrapper>
    {product.vimg?.map((img)=>(
      <VarientImage src={img}/>
      ))}
  
  </SmallImageWrapper>
  </ImageWrapper>
  <Details>
      <div>
          <Title>{product.name}</Title>
      </div>
      <Price>
          ${product.price}
    
      </Price>
      <Desc><span style={{fontWeight: "700", marginRight: "10px"}}>Description:</span>{product.desc}</Desc>
     
  </Details>
</Wrapper> 
<Footer/>
</Container>
  )
}
export default ProductDetails;
